<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="get">
      <div class="ps-form__header">
        <h3>User Information</h3>
      </div>
      <div class="ps-form__content">
        <div class="form-group">
          <label>Name</label>
          <input class="form-control" type="text" :value="auth.user.name" placeholder="Please enter your name..."
          />
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label>Phone Number</label>
              <input class="form-control" type="text" :disabled="auth.user.phone_number !== ''" :value="auth.user.phone_number"
                     placeholder="Please enter phone number..."
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Email</label>
              <input class="form-control" type="text" :value="auth.user.email"
                     placeholder="Please enter your email..."
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group submit">
        <button class="ps-btn" @click.prevent="onSubmit()">
          {{ isLoading ? "Updating..." : "Update" }}
          <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
          <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import global from '@/mixins/global';
import {mapState} from "vuex";
import axios from "axios";
export default {
    mixins:[global],
    computed: {
      ...mapState({
        auth: state => state.auth
      }),
    },
    data() {
      return {
        isLoading: false,
        form: {
          name: "",
          email: "",
          phone_number: "",
          street: '',
          country: '',
          city: '',
          is_default: false,
        },
        errors: null,
        message: null,
        isError: false
      }
    },
    created() {
      if (this.addressId) {
        axios.get('user/' + this.addressId).then((response) => {
          this.form = response.data
          this.form.is_default = response.data.is_default === 1;
        });
      }
    },
    methods: {
      onSubmit() {
        this.isLoading = true;
        const data = {...this.form};

        axios.post('address', data).then((response) => {
          this.isLoading = false;
          if (response.status === 200 || response.status === 201) {
            this.$toasted.success('User profile successfully updated');
            setTimeout(function(){
              this.$router.push({name: 'my_addresses'});
            }, 500);
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            this.$toasted.error('Profile could not be updated. Please enter all required fields.');
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.isLoading = false;
        });
      }
    }
};
</script>

<style>
</style>